import React from "react";
import styled from "styled-components";
import AppConfig from "../Config";
import FaqItem from "./FaqItem";

const AnswerText = styled.div`
  border-top: solid 0.5px #e9e9e9;
  color: #505050;
  padding-top: 20px;
  margin-bottom: 20px;
  font-weight: 300;
  & .highlight {
    font-weight: 500;
  }
`;
const FaqData = [
  {
    question: "如何購買",
    answer: <AnswerText>請參照操作流程。</AnswerText>
  },
  {
    question: "有哪些國家的號碼可以使用",
    answer: (
      <AnswerText>
        目前提供<span className="highlight">香港/美國/加拿大/英國</span>
        等虛擬號碼。
      </AnswerText>
    )
  },
  {
    question: "如何使用號碼",
    answer: (
      <AnswerText>
        登入會員後，進入我的號碼，將會顯示出
        <span className="highlight">所有已購買之號碼</span>。
      </AnswerText>
    )
  },
  {
    question: "如何檢查訊息",
    answer: (
      <AnswerText>
        進入我的號碼後，點擊號碼會列出
        <span className="highlight">該號碼收到的訊息</span>。
      </AnswerText>
    )
  },
  {
    question: "可以收多少則簡訊",
    answer: (
      <AnswerText>
        每個月有<span className="highlight">20則免費簡訊</span>，也提供
        <span className="highlight">儲值購買的功能</span>。
      </AnswerText>
    )
  },
  {
    question: "如何繼續使用已購買的號碼",
    answer: (
      <AnswerText>
        進入我的號碼後，可以點擊該號碼旁邊的
        <span className="highlight">續約延長效期</span>
        ，也可
        <span className="highlight">加購簡訊額度</span>。
      </AnswerText>
    )
  }
];

class FagSection extends React.Component {
  render() {
    return (
      <Wrapper>
        <Container>
          <Title>FAQs</Title>
          {FaqData.map((data, i) => (
            <FaqItem
              key={data.question + i}
              question={data.question}
              answer={data.answer}
            />
          ))}
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #faf7f5;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 60%;
  margin: 20px 0 40px 0;

  @media screen and (max-width: 500px) {
    width: 100%;
    margin: 0 10px;
  }
`;
const Title = styled.div`
  font-size: 5em;
  color: ${AppConfig.global.themeColor};
  margin: 10px;
  align-self: center;
  @media screen and (max-width: 500px) {
    font-size: 3em;
  }
`;

export default FagSection;
