import React from "react";
import styled from "styled-components";
import AppConfig from "../Config";

const themeColor = AppConfig.global.themeColor;

class FaqItem extends React.Component {
  state = {
    isExpanding: false
  };

  render() {
    let { question, answer } = this.props;
    let { isExpanding } = this.state;
    return (
      <Wrapper
        onClick={() => {
          this.setState({ isExpanding: !this.state.isExpanding });
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div className="question">{question}</div>
          <CollapseSign>{isExpanding ? "-" : "+"}</CollapseSign>
        </div>
        {isExpanding && answer}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: white;
  border: solid 1px ${themeColor};
  border-radius: 20px;
  box-shadow: 0 8px 10px -10px ${themeColor};
  padding: 3px 30px;
  margin-bottom: 20px;

  & .question {
    color: ${themeColor};
    font-size: 1em;
    margin: 5px 0;
  }
`;

const CollapseSign = styled.div`
  color: ${themeColor};
  font-size: 1.5em;
  margin-top: -7px;
  cursor: default;
`;

export default FaqItem;
