import React, { Fragmenet } from "react";
import styled from "styled-components";
import * as Widget2 from "./Widget2";
import Image from "./Image";

const steps = [
  {
    image: "../../images/instruction/instruction-01.png",
    text: "輸入\n國家號碼"
  },
  {
    image: "../../images/instruction/instruction-02.png",
    text: "選擇\n週期、數量"
  },
  {
    image: "../../images/instruction/instruction-03.png",
    text: "點選\n『加入購物車』"
  },
  {
    image: "../../images/instruction/instruction-04.png",
    text: "輸入\n個人資料"
  },
  {
    image: "../../images/instruction/instruction-05.png",
    text: "選擇\n付款方式"
  },
  {
    image: "../../images/instruction/instruction-06.png",
    text: "點選『下一步』\n即前往\n付款頁面"
  },
  { image: "../../images/instruction/instruction-07.png", text: "完成付款" },
  {
    image: "../../images/instruction/instruction-08.png",
    text: "立即享受\n簡訊服務"
  }
];
const annotations = [
  "註：每個區域限收20則簡訊",
  "※您可以在“我的電話”中，查詢各別號碼歷史訊息紀錄"
];
class InstructionSection extends React.Component {
  render() {
    return (
      <>
        <Widget2.SectionTitle>
          <div className="line"></div>
          <div className="title">操作流程</div>
          <div className="line"></div>
        </Widget2.SectionTitle>
        <Container>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            {steps.map((step, i) => (
              <StepItem key={i}>
                <ImageWrapper>
                  <Image background src={step.image} />
                </ImageWrapper>
                <div className="text">{step.text}</div>
              </StepItem>
            ))}
          </div>
          <Footer>
            {annotations.map((anno, i) => (
              <div key={i} className="annotation">
                {anno}
              </div>
            ))}
          </Footer>
        </Container>
      </>
    );
  }
}
const Container = styled.div`
  width: 80%;
  padding: 20px 0;
  align-self: center;
  @media screen and (max-width: 500px) {
    width: 90%;
  }
`;
const StepItem = styled.div`
  flex: 1 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 5px;

  & > .text {
    color: #555;
    white-space: pre;
    margin-top: 5px;
    text-align: center;
  }
`;
const ImageWrapper = styled.div`
  height: 80px;
  width: 80px;
  margin-left: -10px;
`;
const Footer = styled.div`
  display: flex;

  justify-content: flex-end;
  margin: 10px 0;
  & > .annotation {
    font-size: 0.8em;
    color: #aaa;
    margin-left: 20px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export default InstructionSection;
