import React, { Fragment } from "react";
import { connect } from "react-redux";
import Selector from "../Selectors";
import ActionCreator from "../ActionCreator";
import styled from "styled-components";
import ProductListItem from "../Components/ProductListItem";
import ProductList from "../Components/ProductSearchList";
import * as Widget2 from "../Components/Widget2";

class ProductSection extends React.Component {
  state = {
    selectedProductId: null,
    productList: []
  };
  componentDidMount() {
    let { appActions } = this.props;
    appActions
      .getProductList()
      .then(data => this.setState({ productList: data.results }));
  }

  render() {
    let { selectedProductId, productList } = this.state;
    let selectedProduct = productList.filter(
      product => product.id === selectedProductId
    )[0];

    return (
      <Wrapper id="product">
        <Widget2.SectionTitle>
          <div className="line"></div>
          <div className="title">搜尋國碼</div>
          <div className="line"></div>
        </Widget2.SectionTitle>
        {/* TODO: ProductSmallItem haven't done yet waiting for api (need product.name_en)*/}
        <div className="product-list">
          <ProductList
            productList={productList}
            onSelect={id => this.setState({ selectedProductId: id })}
          />
        </div>
        {selectedProductId && (
          <div className="product-result">
            <ProductListItem product={selectedProduct} />
          </div>
        )}
      </Wrapper>
    );
  }
}
const Wrapper = styled.div`
  margin-bottom: 80px;

  & > .product-list {
    display: flex;
    justify-content: center;
  }
  & > .product-result {
    display: flex;
    justify-content: center;
  }
`;

export default connect(
  null,
  ActionCreator
)(ProductSection);
