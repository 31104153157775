import React from "react";
import geography from "../../../basic-map/static/world-50m.json";
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography
} from "react-simple-maps";

class SvgMap extends React.Component {
  render() {
    let { onSelected, getMousePosition } = this.props;
    return (
      <ComposableMap style={{ width: "100%" }}>
        <ZoomableGroup>
          <Geographies geography={geography} disableOptimization>
            {(geographies, projection) =>
              geographies.map((geography, i) => (
                <Geography
                  key={`geography-${i}`}
                  cacheId={`geography-${i}`}
                  geography={geography}
                  projection={projection}
                  onClick={geography => {
                    onSelected(geography);
                    getMousePosition(this._getMousePosition(event));
                  }}
                  style={{
                    default: {
                      fill: "#e5e5e5",
                      stroke: "#FFF",
                      strokeWidth: 0.5,
                      outline: "none"
                    },
                    hover: {
                      fill: "#d6744e",
                      outline: "none"
                    },
                    pressed: {
                      fill: "#d55321",
                      outline: "none"
                    }
                  }}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    );
  }
  _getMousePosition = e => {
    return { X: e.clientX, Y: e.clientY };
  };
}

export default SvgMap;
