import React from "react";
import styled from "styled-components";
import AppConfig from "../Config";
import Image from "./Image";
import * as Icon from "./Icon";
import AddToCartButton from "./AddToCartButton";

const themeColor = AppConfig.global.themeColor;
const goldColor = AppConfig["Component/AreaItem"].colorGold;

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        period: "3m",
        quantity: 1,
        type: "new"
      }
    };
  }

  render() {
    let { product } = this.props;
    let { data } = this.state;

    return (
      <FormStyle.Wrapper>
        <div style={{ padding: "10px 0", width: "100%" }}>
          {/* display:'flex',flexDirection:'column',justifyContent:'center' */}
          <FormStyle.Option>
            <label>週期</label>
            <select
              className="input"
              value={data.period}
              onChange={event =>
                this.setState({
                  data: { ...data, period: event.target.value }
                })
              }
            >
              {" "}
              {/* wait for api */}
              <option value="3m">90天</option>
              <option value="6m">180天</option>
              <option value="1y">365天</option>
            </select>
          </FormStyle.Option>
          <FormStyle.Option>
            <label>數量</label>
            <select
              className="input"
              value={data.quantity}
              onChange={event =>
                this.setState({
                  data: { ...data, quantity: event.target.value }
                })
              }
            >
              {" "}
              {/* wait for api */}
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </FormStyle.Option>
          <FormStyle.Option>
            <label>價格</label>
            <div className="origin-price">
              {product[`price_${data.period}`] * data.quantity}
            </div>
            <div className="price">
              {product[`price_${data.period}`] * data.quantity}
            </div>
          </FormStyle.Option>
        </div>
        <FormStyle.Footer>
          <AddToCartButton
            item={product}
            data={data}
            extraCss="border-radius:0; border: 0;"
          />
          <div className="line"></div>
          <AddToCartButton
            item={product}
            data={data}
            extraCss="border-radius:0; border: 0;"
            redirectToCart //for 立即購買
          />
        </FormStyle.Footer>
      </FormStyle.Wrapper>
    );
  }
}

const FormStyle = {
  Wrapper: styled.div`
    width: 100%;
    background-color: white;
  `,
  Option: styled.div`
    color: #343434;
    display: flex;
    margin: 10px auto;
    width: 70%;
    & label {
      margin-right: 10px;
      font-size: 0.9em;
    }
    & .input {
      flex: 1;
    }
    & .origin-price {
      text-decoration: line-through;
      font-weight: 400;
      font-size: 14px;
      color: #bfbfbf;
      &::before {
        content: "$";
      }
    }
    & .price {
      color: #f74600;
      margin: 0 10px;
      &::before {
        content: "$";
        margin-right: 3px;
        font-size: 0.8em;
      }
    }
  `,
  Footer: styled.div`
    width: 100%;
    display: flex;
    & .line {
      height: 100%;
      width: 1px;
      background-color: white;
    }
  `
};

class AreaItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapse: true
    };
  }

  render() {
    let { product } = this.props;
    let { isCollapse } = this.state;
    return (
      <Wrapper isCollapse={isCollapse}>
        <ImageWrapper>
          <Image src={product.image} background />
        </ImageWrapper>
        <Content>
          <div className="title">{product.name}</div>
          <div className="title-baseline"></div>
          <div className="subtitle">{product.country_code}</div>
          <ul className="description">
            <li>{product.description}</li>
          </ul>
        </Content>
        <div style={{ position: "relative" }}>
          <CollapseWrapper isCollapse={isCollapse}>
            <Form product={product} />

            <CollapseButton
              onClick={() =>
                this.setState(state => ({
                  isCollapse: !state.isCollapse
                }))
              }
            >
              {isCollapse ? (
                <Icon.KeyboardArrowDown size="25" color={themeColor} />
              ) : (
                <Icon.KeyboardArrowUp size="25" color={themeColor} />
              )}
            </CollapseButton>
          </CollapseWrapper>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: white;
  width: 270px;
  height: ${props => (props.isCollapse ? 500 : 700)}px;
`;

const ImageWrapper = styled.div`
  height: 300px;
  width: 100%;
  border: solid 2px ${themeColor};
  z-index: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 160px;
  z-index: 1;
  background-color: ${themeColor};
  & .title {
    font-size: 1.3em;
    margin-bottom: 5px;
  }
  & .title-baseline {
    height: 2px;
    width: 50%;
    background-color: ${goldColor};
  }
  & .subtitle {
    color: ${goldColor};
    margin: 8px;
    font-size: 0.9em;
    &::before {
      content: "+";
      margin-right: 8px;
    }
  }
  & .description {
    font-size: 0.8em;
    list-style-type: square;
    max-width: 70%;
    & > li {
      margin-bottom: 3px;
    }
  }
`;

const CollapseWrapper = styled.div`
  position: absolute;
  width: 100%;
  box-shadow: ${props => !props.isCollapse && " 0 1px 14px -8px #000"};
  transform: ${props => props.isCollapse && "translateY(-100%)"};
  transition: transform 0.5s ease-out;
`;

const CollapseButton = styled.div`
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  background-color: white;
  border: solid 2px ${themeColor};
  border-radius: 30px;
  box-shadow: 1px 1px 5px -2px #000;
  &:hover {
    background-color: #eee;
  }
  &:active {
    box-shadow: none;
  }
`;

export default AreaItem;
