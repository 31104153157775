import React from "react";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import styled from "styled-components";
import AreaItem from "./AreaItem";
import * as Widget2 from "./Widget2";
import * as Icon from "./Icon";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ItemRow extends React.Component {
  render() {
    const { items } = this.props;

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around"
        }}
      >
        {items.map(product => (
          <AreaItem key={product.id} product={product} />
        ))}
      </div>
    );
  }
}

class AreaSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: [],
      isSmallScreen: true,
      mounted: false,
      itemPerRow: 1
    };
  }

  componentDidMount() {
    let { appActions } = this.props;

    this.setState({ mounted: true });
    this._checkScreenSize();

    setTimeout(() => {
      //It's a hack to solve height problem https://github.com/FormidableLabs/nuka-carousel/issues/265
      window.dispatchEvent(new Event("resize"));
    }, 0);

    window.onresize = () => {
      this._checkScreenSize();
    };

    appActions.getProductList().then(data => {
      this.setState({ productList: data.results });
    });
  }

  render() {
    const { mounted, itemPerRow } = this.state;
    const itemRows = this._generateItemRows();

    return (
      <>
        <Widget2.SectionTitle>
          <div className="line"></div>
          <div className="title">提供的地區</div>
          <div className="line"></div>
        </Widget2.SectionTitle>

        <Wrapper>
          {mounted && itemRows && (
            <div style={{ position: "relative" }}>
              <Slider
                key={itemPerRow}
                dots={true}
                speed={500}
                style={{
                  height: 600,
                  width: "100%"
                }}
              >
                {itemRows.map((items, idx) => (
                  <ItemRow items={items} key={idx} />
                ))}
              </Slider>

              <div style={{ position: "absolute", right: 10, top: 200 }}>
                <Icon.ArrowForward size={48} color="#d55421" />
              </div>

              <div style={{ position: "absolute", left: 10, top: 200 }}>
                <Icon.ArrowBack size={48} color="#d55421" />
              </div>
            </div>
          )}
        </Wrapper>
      </>
    );
  }

  _generateItemRows = () => {
    let { productList, itemPerRow } = this.state;

    if (!productList) {
      return null;
    }

    let itemRows = [],
      itemsInRow = 0,
      row = [];
    for (let p of productList) {
      if (itemsInRow < itemPerRow) {
        row.push(p);
        itemsInRow++;
      } else {
        itemRows.push(row);
        itemsInRow = 1;
        row = [p];
      }
    }

    if (row.length > 0) {
      itemRows.push(row);
    }

    return itemRows.reverse();
  };

  _checkScreenSize = () => {
    let { itemPerRow } = this.state;
    if (window.matchMedia("(max-width: 500px)").matches) {
      itemPerRow = 1;
    } else if (window.matchMedia("(max-width: 1000px)").matches) {
      itemPerRow = 2;
    } else {
      itemPerRow = 3;
    }
    this.setState({ itemPerRow });
  };
}

const Wrapper = styled.div`
  width: 100%;
  height: 600px;
`;

const ScrollContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  overflow-y: scroll;
  display: flex;
  padding-bottom: 200px;
`;

export default connect(
  (state, ownProps) => ({
    //productList:Selectors.getProductList(state),
  }),
  ActionCreator
)(AreaSection);
