import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "../../Components/Icon";
import Image from "../Image";
import { connect } from "react-redux";
import ActionCreator from "../../ActionCreator";

class ProductSearchList extends Component {
  state = {
    openList: false,
    searchInput: ""
  };

  render() {
    let { searchInput, openList } = this.state;
    let { productList, onSelect } = this.props;

    return (
      <Wrapper>
        <div className="search-section">
          <Icon.Search size={25} color={"white"} style={{ marginLeft: 20 }} />
          <input
            id="Javascript_example"
            className="search"
            placeholder="請輸入國碼"
            onChange={e => this.setState({ searchInput: e.target.value })}
            onFocus={() => this.setState({ openList: true })}
            // onBlur={() => this.setState({ openList: false})}
          />
        </div>

        {openList && (
          <div className="item-container">
            {productList &&
              productList
                .filter(
                  product => product.country_code.indexOf(searchInput) > -1
                )
                .map((product, idx) => (
                  <div
                    className="item"
                    key={idx}
                    onClick={() => onSelect(product.id)}
                  >
                    {product.country_code}
                    {product.name}
                  </div>
                ))}
          </div>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  background: white;
  margin-bottom: 10px;

  & .search-section {
    display: flex;
    align-items: center;

    background-color: #d55421;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 90px;
  }

  & .search {
    flex: 1;
    border: none;
    outline: none;

    border-radius: 10px;
    padding: 15px;
    margin: 20px;
  }

  & .item-container {
    z-index: 5;
    padding: 10px 20px;
    border: 1px solid #d2d2d2;
    border-radius: 0px 0px 10px 10px;
    overflow: scroll;
  }

  & .item {
    padding: 5px;
    color: #4f4f4f;
    font-size: 14px;
    cursor: pointer;

    :hover {
      background-color: #ece9e9;
    }
  }
  ${props => props.css}
`;

export default connect(
  null,
  ActionCreator
)(ProductSearchList);
