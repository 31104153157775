import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "../Icon";

class ProductSmallItem extends Component {
  render() {
    let { product, mousePosition } = this.props;

    return (
      <Wrapper left={mousePosition.X} top={mousePosition.Y}>
        <div className="detail">
          <div className="title">
            {product.country_code}
            {product.name}
          </div>
          <span className="description">{product.description}</span>
          <div className="select">
            週期
            <select>
              {" "}
              {/* wait for api */}
              <option value="1">90天</option>
              <option value="2">180天</option>
              <option value="3">365天</option>
            </select>
          </div>
          <div className="select">
            數量
            <select>
              {" "}
              {/* wait for api */}
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
          </div>
          <div>
            <OriginPrice>${product.price}</OriginPrice>
            <Price>${product.special_price}</Price>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className="button">
            <Icon.ShoppingCart size={20} color={"white"} />
            加入購物車
          </div>
          <div className="button">
            <Icon.ArrowForward size={20} color={"white"} />
            立即購買
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  top: ${props => (props.top || 120) + 20}px;
  left: ${props => (props.left || 150) + 20}px;
  width: 280px;
  position: absolute;
  background-color: white;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.4);
  & > .detail {
    margin: 13px 15px;
    & > .title {
      font-size: 16px;
      font-weight: bold;
    }

    & > .description {
      margin: 5px;
      overflow: hidden;
      font-size: 14px;
      color: #828282;
    }

    & > .select {
      font-size: 14px;
      color: #828282;
    }
  }
  & .button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 30px;
    background-color: #d55421;
    color: white;
    font-size: 12px;
  }
`;
const OriginPrice = styled.div`
  text-decoration: line-through;
  font-weight: 400;
  font-size: 14px;
  color: #bfbfbf;
  ${props => props.css};
`;

const Price = styled.div`
  font-size: 18px;
  color: #f74600;
`;

export default ProductSmallItem;
