import React from "react";
import Carousel from "nuka-carousel";
import styled from "styled-components";
import Image from "./Image";
import AppConfig from "../Config";

const banners = [
  {
    image: "../../images/banner/banner-01.jpg",
    text: "SIMCRET TEXTS\n給您最好的簡訊服務"
  },
  { image: "../../images/banner/banner-02.jpg", text: "" }
];
const bannerHeight = AppConfig.global.bannerHeight;
const themeColor = AppConfig.global.themeColor;

class BannerSection extends React.Component {
  render() {
    return (
      <Carousel
        renderCenterRightControls={() => null}
        renderCenterLeftControls={() => null}
        renderBottomCenterControls={props => (
          <PageDot>
            {Array.from(Array(props.slideCount).keys()).map(i => (
              <li
                key={i}
                style={{
                  color: props.currentSlide === i ? themeColor : "#ddd8"
                }}
                onClick={() => {
                  props.goToSlide(i);
                }}
              >
                &bull;
              </li>
            ))}
          </PageDot>
        )}
        initialSlideHeight={bannerHeight}
        autoplay
        pauseOnHover
      >
        {banners.map((banner, index) => (
          <ImageWrapper key={index}>
            <Image background src={banner.image} />
            {banner.text && <div className="text">{banner.text}</div>}
          </ImageWrapper>
        ))}
      </Carousel>
    );
  }
}
const ImageWrapper = styled.div`
  height: ${bannerHeight}px;
  width: 100%;
  position: relative;
  & .text {
    position: absolute;
    bottom: 100px;
    right: 200px;
    background-color: #5553;
    border-radius: 40px;
    color: white;
    font-size: 1.8em;
    text-shadow: 1px 1px 5px #333;
    white-space: pre;
    letter-spacing: 2px;
    padding: 50px 40px;
    @media screen and (max-width: 500px) {
      border-radius: 20px;
      bottom: 100px;
      right: 30px;
      font-size: 1em;
      padding: 20px;
    }
  }
`;
const PageDot = styled.ul`
  list-style: none;
  & > li {
    display: inline;
    font-size: 1.5em;
    cursor: default;
    margin: 0 5px;
  }
`;

export default BannerSection;
