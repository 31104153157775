import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../ActionCreator";
import { withPage } from "../../Page";
import AppConfig from "../../Config";
import { StaticFluidImage } from "../../Components/StaticImage";
import ReactDOM from "react-dom";
import SvgMap from "../../Components/SvgMap";
import ProductSmallItem from "../../Components/ProductSmallItem";
import BannerSection from "../../Components/BannerSection";
import ProductSection from "../../Components/ProductSection";
import AreaSection from "../../Components/AreaSection";
import FaqSection from "../../Components/FaqSection";
import InstructionSection from "../../Components/InstructionSection";

class HomePage extends React.Component {
  state = {
    selectedCountry: null,
    mousePosition: {}
  };

  render() {
    let config = AppConfig["Templates/LandingPage"];
    let { selectedCountry, mousePosition } = this.state;

    // let selectedProductbyMap = productList.filter(product => product.mane_en === selectedCountry.properties.name)[0]
    // TODO: get product by country en name and pass in <ProductSmallItem/>
    return (
      <Wrapper>
        <div className="banner">
          <div className="rwd-buttons">
            {/* {country_which_has_data.map(item => (
              <div
                className="button"
                onClick={() =>
                  this.setState({
                    selectedCountry: country_which_has_data.filter(
                      country_which_has_data => item.country == country_which_has_data.country
                    )[0].country
                  })
                }
              >
                {item.country_name}
              </div>
            ))} */}
          </div>
          <div className="map">
            {/* <SvgMap
              onSelected={geography =>
                this.setState({ selectedCountry: geography })
              }
              getMousePosition={mousePosition =>
                this.setState({ mousePosition })
              }
            /> */}
          </div>
          <BannerSection />
        </div>
        <AreaSection />
        {/* {selectedCountry && <ProductSmallItem product={selectedProduct} mousePosition={mousePosition}/>} */}
        <ProductSection></ProductSection>
        <InstructionSection />
        <FaqSection />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & > .banner {
    background-color: white;
    position: relative;
    margin-top: ${AppConfig.global.navbarHeight}px; /* for navbar */
    @media screen and (max-width: 500px) {
      margin-top: ${AppConfig.global
        .smallScreenNavbarHeight}px; /* for navbar */
    }
  }

  & > .map {
    @media screen and (max-width: 500px) {
      display: none;
    }
  }
  & > .rwd-buttons {
    display: none;
    & > .button {
      border-radius: 10px;
      width: 200px;
      margin: 20px;
      padding: 20px;
      text-align: center;
      background-color: #dacbbd;
      cursor: pointer;

      :hover {
        background-color: #a59a91;
      }
    }
    @media screen and (max-width: 500px) {
      display: inline;
    }
  }

  ${AppConfig["Templates/LandingPage"].css || ""};
`;

export default withPage(
  connect(
    null,
    ActionCreator
  )(HomePage)
);
